
import RoutesPath from "./RoutesPath";


function App() {
  return (
    <div className="App">
  
    <RoutesPath/>

      
     
    </div>
  );
}

export default App;
