import React from 'react'
import bg from '../assets/bg.jpg'
const LandPage = () => {
  return (
    <div className='lg:mb-0 mb-10'>

      <img src={bg} alt='/'></img>


    </div>
  )
}

export default LandPage